export default {
  namespaced: true,
  state: {
    dialogElement: false,
    type_form_element: null,
    element_form_display: false,
    type_element: null,
    element_id: null,
    element_audio_data: null,
    element_post_data: null,
    element_image_data: null,
    element_audio_file: null,
    element_image_file: null,
    element_pdf_data: null,
    element_pdf_file: null,
    element_description: null,
    element_has_slow_sound: false,
    videos: [],
    video_element_id: null,
    video_element_data: null,
    element_audio_section: null,
    album_element_id: null,
    album_element_data: [],
    model_sub_element: null,
    dialogSubElement: false,
    element_youtube_data: null,
    dialogTimestampVideo: false,
    element_flashcards: [],
    dialogChooseVideo: false,
    type_add_video: null,
  },
  getters: {},
  actions: {},
  mutations: {
    setDialogElement(state, payload) {
      state.dialogElement = payload;
    },
    setTypeFormElement(state, payload) {
      state.type_form_element = payload;
    },
    setElementFormDisplay(state, payload) {
      state.element_form_display = payload;
    },
    setTypeElement(state, payload) {
      state.type_element = payload;
    },
    setElementId(state, payload) {
      state.element_id = payload;
    },
    setElementPostData(state, payload) {
      state.element_post_data = payload;
    },
    setElementAudioData(state, payload) {
      state.element_audio_data = payload;
    },
    setElementImageData(state, payload) {
      state.element_image_data = payload;
    },
    setElementAudioFile(state, payload) {
      state.element_audio_file = payload;
    },
    setElementImageFile(state, payload) {
      state.element_image_file = payload;
    },
    setElementPdfData(state, payload) {
      state.element_pdf_data = payload;
    },
    setElementPdfFile(state, payload) {
      state.element_pdf_file = payload;
    },
    setElementDescription(state, payload) {
      state.element_description = payload;
    },
    setElementHasSlowSound(state, payload) {
      state.element_has_slow_sound = payload;
    },
    setVideos(state, payload) {
      state.videos = payload;
    },
    setVideoElementId(state, payload) {
      state.video_element_id = payload;
    },
    setVideoElementData(state, payload) {
      state.video_element_data = payload;
    },
    setElementAudioSection(state, payload) {
      state.element_audio_section = payload;
    },
    setAlbumElementId(state, payload) {
      state.album_element_id = payload;
    },
    setAlbumElementData(state, payload) {
      state.album_element_data = payload;
    },
    setModelSubElement(state, payload) {
      state.model_sub_element = payload;
    },
    setDialogSubElement(state, payload) {
      state.dialogSubElement = payload;
    },
    setElementYoutube(state, payload) {
      state.element_youtube_data = payload;
    },
    setDialogTimestampVideo(state, payload) {
      state.dialogTimestampVideo = payload;
    },
    setElementFlashcards(state, payload) {
      state.element_flashcards = payload
    },
    setDialogChooseVideo(state, payload) {
      state.dialogChooseVideo = payload
    },
    setTypeAddVideo(state, payload) {
      state.type_add_video = payload
    },
  }
}